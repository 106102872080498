import { CommonAPI } from "../CommonAPI/CommonAPI";

export const DiamondListData = async (page, shape, stockno, sliderState = {}) => {
    console.log("sliderState",page, shape, stockno, sliderState);
    let storeInit = JSON.parse(sessionStorage.getItem("storeInit"));
    const storedData = sessionStorage.getItem("loginUserDetail");
    const islogin = JSON.parse(sessionStorage.getItem("LoginUser"));
    const data = JSON.parse(storedData);
    const customerId = data?.id ?? 0;
    const customerEmail = data?.userid ?? "";
    const { FrontEnd_RegNo } = storeInit;

    let packageId = data?.PackageId ?? 0
    

    try {
        const combinedValue = JSON.stringify({
            PageNo: `${page ?? 1}`,
            PageSize: `${storeInit?.PageSize ?? 50}`,
            OrderBy: "order by carat asc",
            FrontEnd_RegNo: `${FrontEnd_RegNo}`,
            Customerid: `${customerId ?? 0}`,
            PackageId: packageId,
            Shape: `${shape ?? ''}`,
            Polish: "",
            Lab: "",
            Symmetry: "",
            Fluorescence: "",
            FromColor: "",
            ToColor: "",
            FromCarat: "",
            ToCarat: "",
            FromClarity: "",
            ToClarity: "",
            FromCut: "",
            ToCut: "",
            FromPrice:`${sliderState?.price[0]}`,
            ToPrice: `${sliderState?.price[1]}`,
            FromTable: "",
            ToTable: "",
            FromDepth: "",
            ToDepth: "",
            stockno: `${stockno ?? ""}`
        });

        const encodedCombinedValue = btoa(combinedValue);

        console.log("diamondListApi", combinedValue);

        const body = {
            con: `{\"id\":\"\",\"mode\":\"GETDIAMONDLIST\",\"appuserid\":\"${customerEmail}\"}`,
            f: "Header (getCartData)",
            p: encodedCombinedValue,
            dp: combinedValue
        };

        const response = await CommonAPI(body);

        return response;
    } catch (error) {
        console.error("Error fetching cart details:", error);
        throw error;
    }
};
